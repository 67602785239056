$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    .before(
      '<button class="hamburger hamburger--spring" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menu</span></button>'
    )
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  if (toggleMenu) {
    toggleMenu.addEventListener("click", function () {
      var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
      toggleMenu.setAttribute("aria-expanded", !open);
      menu.hidden = !menu.hidden;
    });
  }

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe
    $("html,body").toggleClass("js-menu-open");
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });


  // -----
  // Login
  // -----
  /*$(".login-btn").magnificPopup({
    type: "inline",
    preloader: false,
    focus: "#name",
    closeBtnInside: false,
    closeMarkup:
      '<button type="button" class="mfp-close" aria-label="%title%"><svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#FFF" fill-rule="nonzero"/></svg></button>',
    // When elemened is focused, some mobile browsers in some cases zoom in
    // It looks not nice, so we disable it:
    callbacks: {
      beforeOpen: function () {
        if ($(window).width() < 700) {
          this.st.focus = false;
        } else {
          this.st.focus = "#usr";
        }
      },
    },
  });*/

  // -------
  // Sliders
  // -------
  $(".home-slider").slick({
    dots: true,
    arrows: false,
    autoplay: true,
    customPaging: function (slider, i) {
      var thumb = $(slider.$slides[i]).data();
      // return "<a href>" + (i + 1) + "</a>";
      return (
        "<button type='button'>" +
        (i + 1) +
        "<span class='slick-sr-only'>Go to slide" +
        (i + 1) +
        "</span></button>"
      );
    },
  });

  $(".slider--destacats").slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
  $(".slider--professionals").slick({
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
  $(".slider--espectacles").slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $(".slider--gallery").slick({
    dots: true,
    arrows:false,
    slidesToShow: 7,
    slidesToScroll: 7,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
  $('.close-alert').click(function(){
      $('#alert-text').css("display", "none");
  });

  $('body').on("click", '.close-alert, #alert-text .button', function(){
    var idAnunci = $('#alert-text').data('idanunci');
    $.ajax({url: "/media/php/cookieAnunci.php", data:{'idAnunci' : idAnunci}, success: function(result){}});
  });



  // --------
  // more photos
  // --------

  $("#more-photos").click(function(){
    $('.gallery .column').removeClass('is-hidden');
  $(this).addClass('is-hidden');
      return false;
  });

  // --------
  // headroom
  // --------
  // $(".main-header").headroom();

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
              ? index + 1
              : e.which === 40
              ? "down"
              : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
              ? switchTab(e.currentTarget, tabs[dir])
              : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }
});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
(function () {
  // Get all the headings
  const headings = document.querySelectorAll(".collapsible__title");

  Array.prototype.forEach.call(headings, (heading) => {
    // Give each <h2> a toggle button child
    // with the SVG plus/minus icon
    heading.innerHTML = `
      <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-items-center pb-m">
        ${heading.textContent}
        <svg class="icon is-flex-shrink-0 ml-s" aria-hidden="true" focusable="false">
            <use xlink:href="/media/img/svg-symbols.svg#angle-down"></use>
        </svg>
      </button>
    `;
    // Function to create a node list
    // of the content between this heading and the next
    const getContent = (elem) => {
      let elems = [];
      while (
        elem.nextElementSibling &&
        elem.nextElementSibling.className !== "collapsible__title"
      ) {
        elems.push(elem.nextElementSibling);
        elem = elem.nextElementSibling;
      }

      // Delete the old versions of the content nodes
      elems.forEach((node) => {
        node.parentNode.removeChild(node);
      });

      return elems;
    };

    // Assign the contents to be expanded/collapsed (array)
    let contents = getContent(heading);

    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement("div");
    wrapper.classList.add("flow", "is-size-9");
    wrapper.hidden = true;

    // Add each element of `contents` to `wrapper`
    contents.forEach((node) => {
      wrapper.appendChild(node);
    });

    // Add the wrapped content back into the DOM
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling);

    // Assign the button
    let btn = heading.querySelector("button");

    btn.onclick = () => {
      // Cast the state as a boolean
      let expanded = btn.getAttribute("aria-expanded") === "true" || false;

      // Switch the state
      btn.setAttribute("aria-expanded", !expanded);
      // Switch the content's visibility
      wrapper.hidden = expanded;
    };
  });

  if (
    $(".collapsible__title").length > 0 &&
    window.location.hash.indexOf("#entry-") > -1 &&
    $(window.location.hash).length > 0
  ) {
    $(window.location.hash).find("button").attr("aria-expanded", true);
    $(window.location.hash).next().show();
    $("html, body").animate(
      {
        scrollTop: $(window.location.hash).parent().offset().top,
      },
      1000
    );
  }
})();
